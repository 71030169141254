import { PageSection } from './cmsPage'
import { WelcomeStateEnum } from './homePage'

export interface CTA {
  link: string
  text: string
  openInNewTab?: boolean
}

interface File {
  title?: string
  fileName: string
  contentType: string
  url: string
  details: {
    size: number
    image?: {
      height: number
      width: number
    }
  }
}

export interface Text {
  data: any
  marks: { type: string }[]
  nodeType: 'text'
  value: string
}

interface Hyperlink {
  data: any
  nodeType: 'hyperlink'
  content: Text[]
}

export type ParagraphNode = Text | Hyperlink

interface Paragraph {
  data: any
  nodeType: 'paragraph' | 'heading-1'
  content: ParagraphNode[]
}

interface ListItemContent {
  data: any
  nodeType: 'list-item'
  content: Paragraph[]
}

interface UnorderedListFormat {
  data: any
  nodeType: 'unordered-list'
  content: ListItemContent[]
}

export interface Description {
  content: Paragraph[] | UnorderedListFormat[]
  nodeType: 'document'
}

interface TableCellFormat {
  nodeType: 'table-header-cell' | 'table-cell'
  content: Paragraph[]
}

interface TableRowFormat {
  nodeType: 'table-row'
  content: TableCellFormat[]
}

export interface TabularContentFormat {
  data: any
  nodeType: 'table'
  content: TableRowFormat[]
}

export interface Media {
  title: string
  description: string
  file: File
}

export type Image = Media
export type Video = Media

interface CustomMedia {
  title: string
  webImage: Image
  mobileImage: Image
  imageAlternateText: string
  webImageUrl: string
  mobileImageUrl: string
  hyperlinkUrl: string
  video: any
  videoUrl: string
  thumbnail: any
  isVideo: boolean
}

interface VideoOnly {
  title: string
  webVideo: Video
  mobileVideo: Video
  webThumbnail: Image
  mobileThumbnail: Image
}

interface BackgroundImage {
  title: string
  webImage: Image
  mobileImage: Image
  imageAlternateText: string
}

export type VisibleOnType = 'Logged-In' | 'Logged-Out' | 'Both' | 'None'

export interface HeroCard {
  header: string
  subHeader: string
  description: Description
  primaryCta: CTA
  secondaryCta: CTA
  cardMedia: CustomMedia
  cardBackground: BackgroundImage
  coverVideo?: VideoOnly
  showCoverVideo?: boolean
  visibleOn: VisibleOnType
}

export interface Testimonial {
  name: string
  comment: string
  image: Image
  tagLabel?: string
}

export interface HomePageSection<SectionType> {
  header: string
  headers?: Array<string>
  subHeader?: string
  shortDescription?: string
  sectionContents: SectionType
  visibleOn: VisibleOnType
}

export interface Card {
  title: string
  image: Image
  imageAlternateText: string
  description: Description
  ctaPrimary: CTA
  ctaSecondary: CTA
  tags: string[]
  mobileImage?: Image
}

export interface IntroSection {
  header: string
  subHeader: string
  video: Video
  videoUrl: string
  iosVideo: Video
  iosVideoUrl: string
  thumbnail: Image
  sectionContents: Card[]
  visibleOn: VisibleOnType
}

export interface SocialCard {
  title: string
  header: string
  shortDescription: string
  hyperlink: string
  image: Image
}

export interface InvestersCard {
  title: string
  description: Description
  image: Image
  isInvester: boolean
}

export interface HeroBanner {
  header: string
  shortDescription: string
  cta: CTA
  visibleOn: VisibleOnType
}

export interface VideoStream {
  id: number
  title: string
  thumbnail: Image
  link: string
  channel: string
}

export interface FAQ {
  title: string
  description: Description | string
}

export interface ScrollingStrip {
  title: string
  isEnabled: boolean
  headers: Array<string>
  visibleOn: VisibleOnType
}

export interface ScarcityCard {
  title: string
  scarcityText: string
  media: Media
  thumbnail: Image
  index: number
}

export interface PreviewCard {
  header: string
  shortDescription: string
  cards: ScarcityCard[]
  isMoment: boolean
}

export interface ClubBottomCard {
  title: string
  shortDescription: string
  cardImage: Image
  backgroundImage: Image
  isComingSoon: boolean
}

export interface InTheClubSection {
  title: string
  subHeader: string
  shortDescription: string
  centerCard: HeroCard
  bottomCardsHeader: string
  cards: ClubBottomCard[]
  backgroundImage: Image
  isLive: boolean
  visibleOn: VisibleOnType
}

export interface CricRushBanner {
  title: string
  description: string
  isVideo: boolean
  backgroundImage?: Image
  safariImage?: Image
  video?: Video
  image?: Image
  visibleOn: VisibleOnType
}

interface ConditionalCTA {
  text: string
  loggedInLink: string
  nonLoggedInLink: string
}

export interface NotificationBanner {
  title: string
  cta: ConditionalCTA
  isEnabled: boolean
  visibleOn: VisibleOnType
}

interface SEOEntry {
  route: string
  title: string
  description: string
  keywords: string
}

export interface SEOMap {
  title: string
  routes: Array<SEOEntry>
}

export interface Contests {
  description: Description
  heading: string
  image: Image
  leagueDates: string
  teamsText: string
  leagueDetailsPageLink: string
}

interface CardMedia {
  backgroundImageMobile: Media
  backgroundImageWeb: Media
  bannerImageMobile: Media
  bannerImageWeb: Media
  imageAltText: string
}

export interface PackBanner {
  cardMedia: CardMedia
  description: Description
  heading: string
  componentType: 'HERO_CARD_BANNER'
  isVisible: boolean
  primaryCta: CTA
}
export interface ShowcaseItem {
  heading: string
  image: Image
  imageBackground: Image
  detailPageLink: string
}
export interface KeyPlayerItem {
  image: Image
  heading: string
  subHeading: string
  isVisible: boolean
}
export interface InfoItem {
  componentType: string
  heading: string
  isVisible: boolean
  description: Description
}

export interface HeroCardItem extends PageSection {
  cardMedia: CardMedia
  componentType: 'CAROUSAL_HERO_CARD'
  imagePosition: string
  isFirstFoldBanner: boolean
  primaryCta: CTA
  secondaryCta: CTA
}

export interface AppHeroCardProps {
  userType?: WelcomeStateEnum
  heading?: string
  title?: string
  description?: Description
  media: {
    image?: Media
    backgroundImage: Media
    backgroundVideo?: Media
    isClickableBanner?: boolean
    video?: Media
    thumbnail?: Media
    isVideo?: boolean
    bannerLinkUrl?: string
  }
  primaryCta?: CTA
  secondaryCta?: CTA
  visibilityEndTime?: string
  visibilityStartTime?: string
  eligibleCountries?: string[]
  platform?: string
}

export interface FirstFoldItem {
  primaryCta: CTA
  secondaryCta: CTA
  visibleOn: string
  header: string
  subHeader: string
  description: Description
  cardMedia: CustomMedia
}

export interface HowItWorksProps {
  title: string
  description: Description
}

export enum EligibleCountryValues {
  // eslint-disable-next-line no-unused-vars
  ALL = 'All',
  // eslint-disable-next-line no-unused-vars
  OTHERS = 'Others',
  // eslint-disable-next-line no-unused-vars
  INDIA = 'India',
  // eslint-disable-next-line no-unused-vars
  UK = 'UK',
  // eslint-disable-next-line no-unused-vars
  AUSTRALIA = 'Australia',
}

export const FontMapping = new Map([
  ['Rigid_Square', 'primary'],
  ['Rubik', 'secondary'],
])

export interface FormattingSupportingText {
  isVisible: boolean
  fontSize: string
  highlightColor: string
  fontFamily: string
  fontColor: string
  content: Description
}
